exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-detail-js": () => import("./../../../src/pages/artists/detail.js" /* webpackChunkName: "component---src-pages-artists-detail-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-client-area-js": () => import("./../../../src/pages/client-area.js" /* webpackChunkName: "component---src-pages-client-area-js" */),
  "component---src-pages-conflict-resolution-js": () => import("./../../../src/pages/conflict-resolution.js" /* webpackChunkName: "component---src-pages-conflict-resolution-js" */),
  "component---src-pages-drops-js": () => import("./../../../src/pages/drops.js" /* webpackChunkName: "component---src-pages-drops-js" */),
  "component---src-pages-email-check-js": () => import("./../../../src/pages/email-check.js" /* webpackChunkName: "component---src-pages-email-check-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nft-collections-js": () => import("./../../../src/pages/nft-collections.js" /* webpackChunkName: "component---src-pages-nft-collections-js" */),
  "component---src-pages-pre-sale-js": () => import("./../../../src/pages/pre-sale.js" /* webpackChunkName: "component---src-pages-pre-sale-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-the-project-js": () => import("./../../../src/pages/the-project.js" /* webpackChunkName: "component---src-pages-the-project-js" */)
}

